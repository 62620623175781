import JwtDecode from 'jwt-decode';
import CryptoJs from 'crypto-js';
import { StringDecoder } from 'string_decoder';

import TipoSistemaEnum from '../constants/enumTipoSistema';

export interface PossuiPermissaoResponse {
  permitido: boolean;
  bloqueio?: 'plano' | 'permissao';
}

export interface Loja {
  LojaId: string;
  NomeFantasia: string;
  CidadeUf: string;
  DataHoraUltimaSincronizacao: string;
}

export interface ApplicationNotUpdated {
  notUpdated: boolean;
  count: number;
}

interface GetNameEnumInterface {
  name: string;
  value: number;
}

const TOKEN = 'token';
const REFRESH_TOKEN = 'refreshToken';
const LOJA = 'loja';
const DOMINIO = 'dominio';
const LOGIN_REMEMBER = 'loginRemember';
const APPLICATION_NOT_UPDATED = 'applicationNotUpdated';

export const getEnumByName = (
  enumObj: any,
  name: any
): GetNameEnumInterface => {
  return Object.entries(enumObj.properties as GetNameEnumInterface).filter(
    (obj: any) => obj[1].name === name
  )[0][1] as GetNameEnumInterface;
};

export default {
  setToken(dados: any) {
    localStorage.setItem(TOKEN, dados.token);
    localStorage.setItem(REFRESH_TOKEN, dados.refreshToken);
  },

  setLoja(loja: any) {
    localStorage.setItem(LOJA, JSON.stringify(loja));
  },

  setDominio(dominio: string) {
    localStorage.setItem(DOMINIO, dominio);
  },

  getDadosToken(): any {
    const token = this.getToken();

    if (token) return JwtDecode(token);

    return undefined;
  },

  getToken(): any {
    const token = localStorage.getItem(TOKEN);
    return token;
  },

  getDataDominio(): any {
    const dominio = localStorage.getItem(DOMINIO);
    return dominio;
  },

  getRefreshToken(): any {
    return localStorage.getItem(REFRESH_TOKEN);
  },

  getSecurityStamp(): string {
    const userData = this.getDadosToken();
    return userData.securitystamp;
  },

  getLojas(): Array<Loja> {
    const userData = this.getDadosToken();

    if (userData) {
      return JSON.parse(userData.lojas).sort((loja1, loja2) => {
        if (loja1.NomeFantasia > loja2.NomeFantasia) return 1;
        if (loja1.NomeFantasia < loja2.NomeFantasia) return -1;

        if (loja1.CidadeUf > loja2.CidadeUf) return 1;
        if (loja1.CidadeUf < loja2.CidadeUf) return -1;

        return 1;
      });
    }

    return [] as Array<Loja>;
  },

  administrador(): boolean {
    const userData = this.getDadosToken();

    if (userData) {
      return (
        (userData.administrador || '').toLowerCase() === 'true' ||
        userData.administrador === true
      );
    }

    return false;
  },

  usuarioId(): string {
    const userData = this.getDadosToken();
    return userData.userId;
  },

  getUserIdentityAppInsights(): string {
    const userData = this.getDadosToken();
    return `user: ${userData.email} | id: ${userData.userId} | empresaId: ${userData.empresaId}`;
  },

  empresaId(): StringDecoder {
    const userData = this.getDadosToken();
    return userData.empresaId;
  },

  autenticado(): boolean {
    return this.getToken() != null;
  },

  clearToken() {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(DOMINIO);
  },

  clearTokenAndRedirect() {
    this.clearToken();
    window.location.reload();
  },

  getRememberLogin() {
    const loginString = localStorage.getItem(LOGIN_REMEMBER);

    if (loginString) {
      const usuarioBytes = CryptoJs.AES.decrypt(
        loginString,
        process.env.REACT_APP_KEY_ENCRYPT as string
      );

      return JSON.parse(usuarioBytes.toString(CryptoJs.enc.Utf8));
    }

    return {
      usuario: '',
      senha: '',
      dominio: '',
      lembrarSenha: false,
    };
  },

  getSistema(): any {
    if (
      process.env.NODE_ENV === 'development' ||
      process.env.NODE_ENV === 'test' ||
      process.env.REACT_APP_APP_SISTEMA !== undefined
    ) {
      return getEnumByName(TipoSistemaEnum, process.env.REACT_APP_APP_SISTEMA);
    }

    const sistema = window.location.host.split('.')[1].toLowerCase();
    return getEnumByName(TipoSistemaEnum, sistema);
  },

  setRememberLogin(login) {
    const encryptUsuario = CryptoJs.AES.encrypt(
      JSON.stringify(login),
      process.env.REACT_APP_KEY_ENCRYPT as string
    ).toString();

    localStorage.setItem(LOGIN_REMEMBER, encryptUsuario);
  },

  setAplicacaoDesatualizada(desatualizada: boolean) {
    const applicationNotUpdated = this.getAplicacaoDesatualizada();

    if (desatualizada) {
      applicationNotUpdated.notUpdated = true;
      applicationNotUpdated.count += 1;
    } else {
      applicationNotUpdated.notUpdated = false;
      applicationNotUpdated.count = 0;
    }

    localStorage.setItem(
      APPLICATION_NOT_UPDATED,
      JSON.stringify(applicationNotUpdated)
    );
  },

  getAplicacaoDesatualizada(): ApplicationNotUpdated {
    const aplicacaoDesatualizada = localStorage.getItem(
      APPLICATION_NOT_UPDATED
    );

    if (aplicacaoDesatualizada) {
      const aplicacaoDesatualizadaObj = JSON.parse(
        aplicacaoDesatualizada
      ) as ApplicationNotUpdated;

      return aplicacaoDesatualizadaObj;
    }

    return {
      notUpdated: false,
      count: 0,
    };
  },

  removeRememberLogin() {
    localStorage.removeItem(LOGIN_REMEMBER);
  },
};
